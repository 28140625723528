@import "~antd/dist/antd.less";

.app {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: center;
  position: relative;
}

.app-content {
  padding: 0 50px;
  min-height: 100vh;
  height: auto;
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
}

.header {
  background-color: white;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  height: 65px;
}

.footer {
  text-align: center;
  background: #fff;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  width: 100%;
}

.home {
}

.home-hero {
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  height: 700px;
  background-size: cover;
  color: white;
  .hero-title {
    font-family: "EnviroRegular", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1em;
    font-size: 42px;
    margin-top: 40px;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }
  .hero-subtitle {
    // font-family: "EnviroRegular", sans-serif;
    font-weight: 100;
    font-size: 92px;
    line-height: 1em;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
      font-size: 36px;
    }
  }
}

.home-content {
  margin-top: 700px;
  width: 100%;
  padding: 0 40px;
  .home-subcontent {
    padding: 80px 0 20px 0;
    font-weight: 900;
    font-size: 40px;
    font-family: "EnviroRegular", sans-serif;
    @media screen and (max-width: 844px) {
      font-size: 28px;
      padding: 40px 0 40px 0;
    }
  }
}

.carousel-photo {
  width: 100%;
  height: 300px;
}

.logo {
  float: left;
  width: auto;
  height: 31px;
  .logo-title {
    font-family: "EnviroRegular", sans-serif;
    color: black;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid #ec240d;
    }
  }
  @media screen and (max-width: 844px) {
    .logo-title {
      display: none;
    }
  }
}

.page {
  margin-top: 48px;
  margin-bottom: 40px;
  min-height: 100vh;
  margin: 24px 12% 48px 12%;
  @media screen and (max-width: 768px) {
    margin: 24px 5% 48px 5%;
    h2 {
      font-size: 0.9em;
    }
  }
}

.page-title {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  margin: 40px 0;
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 0.9em;
    }
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.stat-card-body {
  margin-top: 12px;
  padding-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid black;
  .stat-card-content {
    display: inline-flex;
    width: 100%;
  }
  p {
    margin-bottom: 0;
  }
}

.icon-container {
  margin: 0 5px;
  span {
    margin: 0 2px;
  }
}

.header-menu {
  text-align: right;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    font-size: 0.8em;
  }
}

// ANT OVERRIDES
.ant-layout {
  background-color: #fff;
}

.ant-layout-content {
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-menu-horizontal {
  border: none;
}

.stat-card {
  .ant-card-head {
    background-color: #000;
    color: #fff;
    font-size: 18px;
    height: 40px;
    .ant-card-head-title {
      padding: 0;
    }
  }
}

.ant-card-head-title {
  white-space: normal;
}

.faq-card {
  .ant-card-head {
    background-color: #000;
    color: #fff;
    font-size: 18px;
  }
}

.ant-tabs-tab {
  font-weight: 900;
  font-size: 1.25em;
  margin-top: 20px;
  @media screen and (max-width: 844px) {
    font-size: 0.8em;
  }
}

.ant-list-item {
  text-align: left;
}

.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ant-table-cell {
  img {
    width: 100px;
    height: 100px;
  }
  @media screen and (max-width: 844px) {
    font-size: 0.7em;
    img {
      width: 40px;
      height: 40px;
    }
  }
}

@primary-color: #EC240D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);